import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
	Combobox,
	ComboboxInput,
	ComboboxPopover,
	ComboboxList,
	ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import { makeStyles } from "@material-ui/core/styles";
import Map from "./Map";
import { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { NoEncryption } from "@material-ui/icons";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  latlng: {
    paddingLeft: 10,
    paddingTop: 5,
    [theme.breakpoints.down("sm")]: {
      fontSize: "smaller",
      paddingLeft: 0,
      paddingTop: 0
    }
    },
    mapSearchAutocomplete: {
      color: "black"
    }
}));

const MapSearchAutocomplete = ({ setMapData, mapData }) => {

  const classes = useStyles();
  const theme = useTheme();
  const ifSmall = useMediaQuery(theme.breakpoints.down('sm'));

    let {
      ready,
      value,
      setValue,
      suggestions: { status, data },
      clearSuggestions,
    } = usePlacesAutocomplete();

      useEffect(() => {
        setValue(mapData?.address, false);
      }, [mapData?.address, setValue]);  
    
    const handleSelect = async (address, fromMarker) => {
      setValue(address, false);
      clearSuggestions();
      // if this function was called from the map component by moving a marker, just setValue to new address, other map data has already been set. 
      // otherwise if this function is called from the combo box autocomplete input, we want to get the new mapData and set it.
      if (!fromMarker) {
        const results = await getGeocode({ address });
        const { lat, lng } = await getLatLng(results[0]);
        setMapData({address: (results[0]?.formatted_address ? results[0].formatted_address : address), coords: {lat: lat, lng: lng }});
        console.log('setting map data form search box select new object: ' + JSON.stringify(mapData))
      }

    };
  
    return (
      <>
      <Grid container={true} spacing={12}>
        <Grid md={7} xs={12}>
    <Combobox onSelect={handleSelect} >
      <ComboboxInput
        value={value}
        onChange={(e) => setValue(e.target.value)}
        disabled={!ready}
        className="combobox-input"
        placeholder="Search an address"
        autoComplete="off"
        />
      <ComboboxPopover portal={false} className={classes.mapSearchAutocomplete}>
        <ComboboxList className={classes.mapSearchAutocomplete}>
          {status === "OK" &&
            data.map(({ place_id, description }) => (
              <ComboboxOption key={place_id} value={description} />
            ))}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
    </Grid>
    <Grid md={5} xs={12}>
    <div className={classes.latlng}>
      <span style={{fontWeight: 'bold'}}>Latitude: </span>{mapData?.coords?.lat}
    {!ifSmall && <br/>}
    <span style={!ifSmall ? {fontWeight: 'bold'} : {fontWeight: 'bold', paddingLeft: 10}}>Longitude: </span>{mapData?.coords?.lng}
    </div>
    </Grid>
    </Grid>
    <Map setMapData={setMapData} mapData={mapData} callSearchBoxSelect={handleSelect}/>
    </>
    );
  };
export default MapSearchAutocomplete;


