import React, { createContext, useState } from 'react';

// Create the context
export const CategoryContext = createContext();

// Create the provider component
export const CategoryProvider = ({ children }) => {

  // Create state inside the provider
  const [category, setCategory] = useState('shooting');

  return (
    <CategoryContext.Provider value={{ category, setCategory }}>
      {children}
    </CategoryContext.Provider>
  );
};
