export const shootingTypes = [
    {orderNumber: 1, type: "SET"},
    {orderNumber: 2, type: "CREW PARKING"},
    {orderNumber: 3, type: "BASE CAMP"},
    {orderNumber: 4, type: "WORK TRUCKS"},
    {orderNumber: 5, type: "BREAKFAST"},
    {orderNumber: 6, type: "CATERING"},
    {orderNumber: 7, type: "EXTRAS PARKING"},
    {orderNumber: 8, type: "EXTRAS PROCESSING"},
    {orderNumber: 9, type: "EXTRAS HOLDING"},
    {orderNumber: 10, type: "EMERGENCY SHELTER"},
    {orderNumber: 11, type: "HOSPITAL"}
]

export const prepwrapTypes = [
    {orderNumber: 1, type: "PREP / WRAP SET"},
    {orderNumber: 2, type: "PREP / WRAP CREW PARKING"},
    {orderNumber: 3, type: "PREP / WRAP WORK TRUCKS"},
    {orderNumber: 4, type: "HOSPITAL"}
]

export const productionTypes = [
    {orderNumber: 1, type: "STAGE"},
    {orderNumber: 2, type: "PRODUCTION OFFICE"},
    {orderNumber: 3, type: "CREW HOTEL"},
    {orderNumber: 4, type: "TRANSPO LOT"},
    {orderNumber: 5, type: "CONSTRUCTIOIN MILL"},
    {orderNumber: 6, type: "SET DEC WAREHOUSE"},
    {orderNumber: 7, type: "SPFX WORKSHOP"},
    {orderNumber: 8, type: "WARDROBE FITTINGS"}
]