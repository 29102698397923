import React, { useState, useEffect, useContext } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useForm } from "react-hook-form";
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { createLocation } from "../util/db";
import { DateContext } from '../providers/DateProvider';

const todaysDate = new Date().toDateString();

function CopyLocationSelectGroupDialog(props) {
  const [pending, setPending] = useState(false);
  const [filteredGroups, setFilteredGroups] = useState(props.category !== 'production' ? props.listOfGroupsAndDays?.filter(item => item.day?.indexOf(props.copyDay) !== -1) : props.listOfGroupsAndDays);
  const [groupToCopyTo, setGroupToCopyTo] = useState(null);
  const { register, handleSubmit, errors } = useForm();
  const { setDay, setDayStamp } = useContext(DateContext);
  const copyDateStamp = new Date(props.copyDay);
  copyDateStamp.setHours(0,0,0,0);
  const [copyDayStamp, setCopyDayStamp] = useState(copyDateStamp.getTime());

  const handleChange = (event) => { 
    setGroupToCopyTo(event.target.value);
  };
  
    useEffect(() => {
      setFilteredGroups(props.category !== 'production' ? props.listOfGroupsAndDays?.filter(group => 
        ((group.startDate && group.endDate) ? (group.endDate >= copyDayStamp && group.startDate <= copyDayStamp) : group.day?.indexOf(props.copyDay) !== -1)
      ) : props.listOfGroupsAndDays)
    }, [copyDayStamp, props.category, props.copyDay, props.listOfGroupsAndDays]);  

    const onSubmit = () => {
      setPending(true);
      copyLocation();
    }

  const copyLocation = () => {
      //create the location to the group
      var newLocation = {}
      
      if (props.category !== 'production') {
        newLocation = {
        name: props.location?.name.toUpperCase(),
        type: props.location?.type,
        typeOrder: props.location?.typeOrder,
        mapData: props.location?.mapData,
        category: props.location?.category,
        setName: props.location?.setName,
        day: props.copyDay,
        groupId: groupToCopyTo
      };
    } else {
      newLocation = {
        name: props.location?.name.toUpperCase(),
        type: props.location?.type,
        typeOrder: props.location?.typeOrder,
        mapData: props.location?.mapData,
        category: props.location?.category,
        day: props.copyDay,
        groupId: groupToCopyTo
      };
    }
      createLocation({ productionID: props.location?.productionID, ...newLocation });
      handleCloseCopyLocationSelectGroupDialog();
      if (props.copyDay && props.category !== 'production') {
        setDay(props.copyDay);
        setDayStamp(copyDayStamp);
      }
    };

    const handleCloseCopyLocationSelectGroupDialog = () => {
      setPending(false);
      props.setOpen(false);  
    }

  return (
    <>
      {/* DELETE CUSTOM TYPE */}
      <Dialog
            open={props.open}
            onClose={handleCloseCopyLocationSelectGroupDialog}
        >
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle style={{paddingBottom: 0}}>
        {filteredGroups?.length > 0 && props.category !== 'production' ? `Copy "${props.location?.type} ${props.location?.setName ? ' - ' + props.location?.setName : '' } - ${props.location?.name}" to ${props.copyDay == todaysDate ? "today" : props.copyDay}` : filteredGroups?.length > 0 && props.category === 'production' ? `Copy ${props.location?.name}` : `Sorry, there are no groups on ${props.copyDay} to copy to!` }
        </DialogTitle>
      <DialogContent>
        {!filteredGroups?.length < 1 && <DialogContentText>
          Pick a group to copy to:
        </DialogContentText>}
      <Grid item={true}>

        <FormGroup>
        <FormControl component="fieldset">

        <RadioGroup aria-label="groups" name="groups" value={groupToCopyTo} onChange={handleChange}>
        {filteredGroups?.map(group => (
              <FormControlLabel value={group.id} control={<Radio />} label={group.name} />
                  ))}
        </RadioGroup>
        </FormControl>
        </FormGroup>
        </Grid>
        <DialogActions>
          <Button variant="contained" autoFocus onClick={handleCloseCopyLocationSelectGroupDialog} color="secondary">
            Cancel
          </Button>
          <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={pending || !filteredGroups?.length || !groupToCopyTo}
              >
                {!pending && <span>COPY</span>}
                {pending && <CircularProgress size={28} />}
              </Button>
        </DialogActions>
        </DialogContent>
      </form>
      </Dialog>
      </>
  );
}

export default CopyLocationSelectGroupDialog;
