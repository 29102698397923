import React, { useState, useEffect, createRef, useRef } from "react";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MapDirections from "./MapDirections";
import { jsPDF } from "jspdf";
import * as htmlToImage from 'html-to-image';

const useStyles = makeStyles(() => ({
    content: {
      paddingBottom: 24
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        padding: 10,
        gap: 20
    },
    buttonBar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 10,
      gap: 20
    }
  }));


function CreateMapDialog(props) {
    const classes = useStyles();
    const theme = useTheme();
    const ifSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const [to, setTo] = useState();
    const [from, setFrom] = useState();
    const canvasRef = useRef();
    const [disableUI, setDisableUI] = useState(false);
    const [generatingPDF, setGeneratingPDF] = useState(false);

    const closeAndClear = () => {
        setTo();
        setGeneratingPDF(false);
        setFrom();
        props.setCreateMapDialogOpened(false);
    }
    const setFromObject = (locId) => {
        let local = props.fromLocations?.find(i => i.id === locId);
        setFrom(local);
    }
    const setToObject = (locId) => {
        let local = props.destinationLocations?.find(i => i.id === locId);
        setTo(local);
    }

    const takeScreenShot = async (node) => {
      const dataURI = await htmlToImage.toJpeg(node, {includeQueryParams: true});
      return dataURI;
    };

    const convertScreenShotToPDF = async (img) => {
      var pdf = new jsPDF();
      var imgProps= pdf.getImageProperties(img);
      var pdfWidth = pdf.internal.pageSize.getWidth();
      var pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(img, 'PNG', 0, 0, pdfWidth, pdfHeight, undefined, 'FAST');

      return pdf;
    }

    const downloadPDF = (pdf) => {
      var pdfName = from.name + '-to-' + to.name + ".pdf";
      pdfName = pdfName.replace(/\s+/g, '-').toLowerCase();
      pdf.save(pdfName);
    }; 

    const exportMapToPDF = (domElement) => {
      
      setDisableUI(true);
      setGeneratingPDF(true);

      takeScreenShot(domElement)
        .then((img)=>{
          convertScreenShotToPDF(img)
            .then((pdf) => {
              downloadPDF(pdf);
              setGeneratingPDF(false);
              setDisableUI(false);
            })
            .catch((convertErr) => {
              console.log('an error occurred in convert to pdf portion of map generation.. ' + convertErr);
              setGeneratingPDF(false);
              setDisableUI(false);
            })
        })
      .catch((screenshotErr)=>{
        console.log('an error occurred in screenshot portion of map generation.. ' + screenshotErr);
        setGeneratingPDF(false);
        setDisableUI(false);
      })
    };
  
  return (
    <Dialog 
    disableEnforceFocus
    style={{ pointerEvents: 'none' }}
    PaperProps={{ style: { pointerEvents: 'auto' } }}
    open={props.createMapDialogOpened} onClose={props.onDone} fullScreen={ifSmall} fullWidth={true} maxWidth='xl'>
     <div className={classes.header}>
     <DialogTitle className="editHeader">Create Map</DialogTitle>
      <Button style={{marginLeft: 'auto'}}
                variant="contained"
                color="secondary"
                size="large"
                onClick={closeAndClear}
              >
                Cancel
              </Button>
              <Button style={{marginRight: 15}}
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                onClick={()=> exportMapToPDF(canvasRef.current)}
                disabled={generatingPDF || !from || !to}
              >
                
                {generatingPDF === false && <span>SAVE PDF</span>}

                {generatingPDF === true &&  <span>CREATING PDF...</span>}
              </Button>
     </div>

      <DialogContent className={classes.content}>
      <Grid container={true} spacing={3} style={{marginBottom: 10}}>
<Grid item={true} xs={ ifSmall ? 12 : 6 }>
  <TextField
  variant="outlined"
  id="from"
  label="FROM"
  inputProps={{ style: { textTransform: "uppercase" } }}
  name="from"
  select  
  fullWidth
  onChange={(e) => setFromObject(e.target?.value)}
  >
  {props.fromLocations?.map(loc => (
  <MenuItem key={loc.id} value={loc.id}>
   {loc.type} {loc.setName && ` - "${loc.setName}"`} {` - ${loc.mapData.address}`}
  </MenuItem>
   ))}
   </TextField>
</Grid>

<Grid item={true} xs={ ifSmall ? 12 : 6 }>
  <TextField
  variant="outlined"
  id="dest"
  label="TO"
  inputProps={{ style: { textTransform: "uppercase" } }}
  name="dest"
  select  
  fullWidth
  onChange={(e) => setToObject(e.target?.value)}
  >
  {props.destinationLocations?.map(loc => (
  <MenuItem key={loc.id} value={loc.id}>
   {loc.type} {loc.setName && ` - "${loc.setName}"`} {` - ${loc.mapData.address}`}
  </MenuItem>
   ))}
      </TextField>
</Grid>

</Grid>
   {from && to &&  <div ref={canvasRef}><MapDirections to={to} from={from} disableDefaultUI={disableUI}/></div>}
      </DialogContent>
    </Dialog>
  )
}

export default React.memo(CreateMapDialog);
