import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useForm } from "react-hook-form";
import { useAuth } from "./../util/auth";
import { createProduction, checkIfProductionNameExists } from "../util/db";

function AuthForm(props) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const { handleSubmit, register, errors, getValues } = useForm();

  const submitHandlersByType = {
    signin: ({ email, pass }) => {
      return auth.signin(email, pass).then((user) => {
        // Call auth complete handler
        props.onAuth(user);
      });
    },

    signup: async ({ email, pass, businessName, businessAddress, productionName, productionNameAsShown }) => {
      props.onFormAlert(null);
      var productionData = { businessName: businessName, businessAddress: businessAddress, productionName: productionName, productionNameAsShown: productionNameAsShown, productionAccountEmail: email }
      var nameExists = await checkIfProductionNameExists(productionName);
      if (nameExists) {
        props.onFormAlert({
          type: "error",
          message: "Production name already esists.",
        });
        setPending(false);
        return;
      } else {
        return auth.signup(email, pass,).then((user) => {
          // add new user/productions production data to productions collection
          createProduction(user.uid, productionData)
          // Call auth complete handler
          props.onAuth(user);
        });
      }
    },
    forgotpass: ({ email }) => {
      return auth.sendPasswordResetEmail(email).then(() => {
        setPending(false);
        // Show success alert message
        props.onFormAlert({
          type: "success",
          message: "Password reset email sent",
        });
      });
    },
    changepass: ({ pass }) => {
      return auth.confirmPasswordReset(pass).then(() => {
        setPending(false);
        // Show success alert message
        props.onFormAlert({
          type: "success",
          message: "Your password has been changed",
        });
      });
    },
  };

  // Handle form submission
  const onSubmit = ({ email, pass, businessName, businessAddress, productionName, productionNameAsShown }) => {
    // Show pending indicator
    setPending(true);

    // Call submit handler for auth type
    submitHandlersByType[props.type]({
      email,
      pass,
      businessName,
      businessAddress,
      productionName,
      productionNameAsShown
    }).catch((error) => {
      setPending(false);
      // Show error alert message
      props.onFormAlert({
        type: "error",
        message: error.message,
      });
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container={true} spacing={2}>

        {["signup"].includes(props.type) && (
          <Grid item={true} xs={12}>
            <TextField
              variant="outlined"
              type="text"
              label="Business Name"
              name="businessName"
              error={errors.businessName ? true : false}
              helperText={errors.businessName && errors.businessName.message}
              fullWidth={true}
              inputRef={register({
                required: "Please enter a business name",
              })}
            />
          </Grid>
        )}
        {["signup"].includes(props.type) && (
          <Grid item={true} xs={12}>
            <TextField
              variant="outlined"
              type="text"
              label="Business Address"
              name="businessAddress"
              error={errors.businessAddress ? true : false}
              helperText={errors.businessAddress && errors.businessAddress.message}
              fullWidth={true}
              inputRef={register({
                required: "Please enter your business address",
              })}
            />
          </Grid>
        )}
        {["signup"].includes(props.type) && (
          <Grid item={true} xs={12}>
            <TextField
              variant="outlined"
              type="text"
              label="Production Name"
              name="productionName"
              error={errors.productionName ? true : false}
              helperText={errors.productionName && errors.productionName.message}
              fullWidth={true}
              inputRef={register({
                required: "Please enter production name",
              })}
            />
          </Grid>
        )}
        {["signup"].includes(props.type) && (
          <Grid item={true} xs={12}>
            <TextField
              variant="outlined"
              type="text"
              label="Production Name as Shown on Directionals"
              name="productionNameAsShown"
              error={errors.productionNameAsShown ? true : false}
              helperText={errors.productionNameAsShown && errors.productionNameAsShown.message}
              fullWidth={true}
              inputRef={register({
                required: "Please enter production name as shown on directionals",
              })}
            />
          </Grid>
        )}
        {["signup", "signin", "forgotpass"].includes(props.type) && (
          <Grid item={true} xs={12}>
            <TextField
              variant="outlined"
              type="email"
              label="Email"
              name="email"
              placeholder="user@example.com"
              error={errors.email ? true : false}
              helperText={errors.email && errors.email.message}
              fullWidth={true}
              inputRef={register({
                required: "Please enter your email",
              })}
            />
          </Grid>
        )}

        {["signup", "signin", "changepass"].includes(props.type) && (
          <Grid item={true} xs={12}>
            <TextField
              variant="outlined"
              type="password"
              label="Password"
              name="pass"
              error={errors.pass ? true : false}
              helperText={errors.pass && errors.pass.message}
              fullWidth={true}
              inputRef={register({
                required: "Please enter a password",
              })}
            />
          </Grid>
        )}

        {["signup", "changepass"].includes(props.type) && (
          <Grid item={true} xs={12}>
            <TextField
              variant="outlined"
              type="password"
              label="Confirm Password"
              name="confirmPass"
              error={errors.confirmPass ? true : false}
              helperText={errors.confirmPass && errors.confirmPass.message}
              fullWidth={true}
              inputRef={register({
                required: "Please enter your password again",
                validate: (value) => {
                  if (value === getValues().pass) {
                    return true;
                  } else {
                    return "This doesn't match your password";
                  }
                },
              })}
            />
          </Grid>
        )}

        <Grid item={true} xs={12}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled={pending}
            fullWidth={true}
          >
            {!pending && <span>{props.buttonAction}</span>}

            {pending && <CircularProgress size={28} />}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default AuthForm;
