import React, {useState, useCallback, useEffect, useContext} from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import { useLocationGroupsByProductionID } from "../util/db";
import {CircularProgress} from "@material-ui/core";
import LocationGroupListItem from "./LocationGroupListItem";
import Button from "@material-ui/core/Button";
import { createLocationGroup } from "../util/db";
import { DateContext } from '../providers/DateProvider';
import { CategoryContext } from "../providers/CategoryProvider";
import {DndContext} from '@dnd-kit/core';
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { updateLocationGroup } from "../util/db";

function LocationGroupList(props) {
  const { day, dayStamp } = useContext(DateContext);
  const { category, setCategory } = useContext(CategoryContext);

  const {
    data: groups,
    status: groupsStatus,
    error: groupsError,
  } = useLocationGroupsByProductionID(props.productionId);

  const groupsAreEmpty = !groups || groups?.length === 0;
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [listOfGroupsAndDays, setListOfGroupsAndDays] = useState(groups?.filter(group => group?.category?.includes(category)));

  const filterGroups = useCallback(() => {
    let filty = '';
    if (category !== 'production') {
    filty = groups?.filter(group => group?.category?.includes(category)).filter(group => 
      // Does group have start and end date? then check if day is within the range. Otherwise, just use the group.day
      (group.startDate && group.endDate) ? (group.endDate >= dayStamp && group.startDate <= dayStamp) : day === group.day);
    } else {
    filty = groups?.filter(group => group?.category?.includes(category))
    }
    // setFilteredGroups(filty);
    setFilteredGroups(filty?.sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1));
  }, [category, groups, day, dayStamp])

  // apply group filter and rerender any time one of the following dependencies changes
  useEffect(() => {
      filterGroups();
  }, [day, dayStamp, filterGroups, category, groups]);

  // update list of groups and days and rerender any time one of the following dependencies changes
  useEffect(() => {
    setListOfGroupsAndDays(groups?.filter(group => group?.category?.includes(category)));
  }, [category, groups]);

  const createNewLocationGroup = () => {
    // var randomHex = '#'+(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0');
    //random hex from our list:
    let colors = [ '#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50', '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', '#ff5722', '#795548', '#607d8b' ];

    let randomHex = Math.floor(Math.random()*colors.length);
    let data = {productionId: props.productionId, name: `LOCATION GROUP ${filteredGroups?.length+1}`, groupColor: colors[randomHex], day: day, startDate: dayStamp, endDate: dayStamp, category: category, customTypes: [], sortOrder: filteredGroups?.length};
    createLocationGroup(data);
  }

  const handleOnDragEnd = (event) => {
    const { active, over} = event;
    console.log({active, over})
    if (over && active.id !== over.id){
      setFilteredGroups(filteredGroups => {
        const oldIndex = filteredGroups.findIndex(group => group.id === active.id);
        const newIndex = filteredGroups.findIndex(group => group.id === over.id);
        // console.log('updating ' + active.id + ' to ' + newIndex);

        // here, loop through each group and update the sort order to it's current position in the array!
        // return arrayMove(filteredGroups, oldIndex, newIndex);
        const newSortFilteredGroups = arrayMove(filteredGroups, oldIndex, newIndex);
        newSortFilteredGroups.forEach((group, index) => {
          console.log(group.id + ' at position ' + index)
          updateLocationGroup(group.id, {sortOrder: index});
          if (index === filteredGroups.length - 1) {
            console.log(filteredGroups);
            setFilteredGroups(newSortFilteredGroups)
          }
        });
      });
    }
  }

  return (
    <>
    {groupsError && (
      <Box mb={3}>
        <Alert severity="error">{groupsError.message}</Alert>
      </Box>
    )}

        {(groupsStatus === "loading" || groupsAreEmpty) && (
          <Box py={2} px={3} align="center">
            {groupsStatus === "loading" && <CircularProgress size={32} />}

            {groupsStatus !== "loading" && groupsStatus && (
              <>No location groups created yet.</>
            )}
          </Box>
        )}
        

          {groupsStatus !== "loading" && groups && (groups?.length > 0) && filteredGroups &&
            <DndContext 
              modifiers={[restrictToVerticalAxis]}
              onDragEnd={handleOnDragEnd}
            >
              <SortableContext items={filteredGroups}>
                {filteredGroups?.map((group, index) => (
                    <div key={index}>
                    <LocationGroupListItem key={index} numberOfGroupsInList={filteredGroups?.length} sortOrder={group.sortOrder} groupId={group.id} groupName={group.name} groupColor={group.groupColor} lostCallNumber={group.lostCallNumber} groupCustomTypes={group.customTypes} productionId={props.productionId} listOfGroupsAndDays={listOfGroupsAndDays} startDate={group.startDate} endDate={group.endDate} day={group.day}/>
                    <br/>
                    </div>                  
                ))}
              </SortableContext>
            </DndContext>}


        {groupsStatus !== "loading" && groups && (groups?.length > 0) && !filteredGroups?.length && 
          <Box py={2} px={3} align="center">
          <>No location groups created yet.</>
        </Box>
        }



            <Button
                variant="contained"
                size="medium"
                color="primary"
                onClick={createNewLocationGroup}
              >
                + Locations Group
              </Button>
    </>
  );
}

export default LocationGroupList;
