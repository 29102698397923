import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useForm } from "react-hook-form";
import { updateLocationGroupCustomTypes } from "../util/db";
import { useTheme } from '@material-ui/core/styles';

function CustomTypeDialogCreate(props) {
  const [pending, setPending] = useState(false);
  const [text, setText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { register, handleSubmit, errors } = useForm();

    const addCustomType = (data) => {
      setPending(true);
      updateLocationGroupCustomTypes(props.groupId, {orderNumber: props.listLength + 1, type: data.newCustomType.toUpperCase()})
      props.setOpen(false);
      props.setType(data.newCustomType.toUpperCase());
      setText('');
      setPending(false);
    }

    const handleTextChange = (value) => {
      setText(value.trim());
    }

    const handleCloseCreateCustomType = () => {
    props.setOpen(false);
    };

    useEffect(() => {
      if (props.fullTypesList.length > 0) {
        if (props.fullTypesList?.some(listType => listType['type'].toUpperCase() === text.toUpperCase()) ) {
          setErrorMessage('Type already exists.')
        }
        // else if (text === '' || !text) {
        //   setErrorMessage('Type cannot be empty.')
        // } 
        else {
          setErrorMessage('');
        }
      }
    }, [text, props.fullTypesList]);

  return (
    <>
      {/* CREATE CUSTOM TYPE */}
      <Dialog
            open={props.open}
            onClose={handleCloseCreateCustomType}
        >
        <form onSubmit={handleSubmit(addCustomType)}>
        <DialogTitle>Create Custom Type</DialogTitle>
        <DialogContent>
          <DialogContentText>
          <Grid item={true}>
          <TextField
                autoComplete='off'
                variant="outlined"
                type="text"
                label="CUSTOM TYPE"
                placeholder="CUSTOM TYPE"
                name="newCustomType"
                onChange={(e) => handleTextChange(e.target?.value)}
                error={errorMessage !== '' || errors.newCustomType ? true : false}
                helperText={errorMessage !== '' ? errorMessage : errors.newCustomType ? errors.newCustomType.message : ''}
                fullWidth={true}
                autoFocus={true}
                inputProps={{ style: { textTransform: "uppercase" } }}            
                inputRef={register({
                  required: "Please enter a custom type",
                })}
              />
              </Grid>
         </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleCloseCreateCustomType} color="secondary">
            Cancel
          </Button>
          <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={pending || errorMessage !== ''}
              >
                {!pending && <span>SAVE</span>}
                {pending && <CircularProgress size={28} />}
              </Button>
        </DialogActions>
        </form>
      </Dialog>
      </>
  );
}

export default CustomTypeDialogCreate;
