import React, { useState } from "react";
import DateCarousel from "./DateCarousel";
import LocationGroupList from "./LocationGroupList";
import LocationFilterBar from "./LocationFilterBar";

function DashboardLocations(props) {
  const [locationCategory, setLocationCategory] = useState('shooting');

  return (
    <>              
      <DateCarousel
          size={4}
          textAlign="center"
          disabled={locationCategory === 'production'}
        />
    <LocationFilterBar /> 
    <LocationGroupList productionId={props.productionId} />
    </>
  );
}

export default DashboardLocations;
 