import { React, useState } from "react";
import Meta from "./../components/Meta";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import DashboardSection from "./../components/DashboardSection";
import { requireAuth } from "./../util/auth";
import { useProductionsByOwner } from "../util/db";
import { useAuth } from "./../util/auth";
import { useJsApiLoader } from "@react-google-maps/api";
import { SearchProvider } from "../providers/SearchProvider";
import { DateProvider } from "../providers/DateProvider";
import { CategoryProvider } from "../providers/CategoryProvider";

function DashboardPage(props) {
  const auth = useAuth();

  const {
    data: productions,
    status: productionsStatus,
    error: productionsError,
  } = useProductionsByOwner(auth.user.uid);

  const productionsAreEmpty = !productions || productions.length === 0;
  
  if (!productionsAreEmpty) {
    localStorage.setItem('production', JSON.stringify(productions[0]));
  }

  const [ libraries ] = useState(['places', 'maps', 'geocoding']);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });    

  return isLoaded ? (
    <>
    <Meta title="showNAV Productions Dashboard" />

    {productionsError && (
        <Box mb={3}>
          <Alert severity="error">{productionsError.message}</Alert>
        </Box>
      )}

    {(productionsStatus === "loading" || productionsAreEmpty) && (
        <Box py={5} px={3} align="center">
          {productionsStatus === "loading" && <CircularProgress size={32} />}

          {productionsStatus !== "loading" && productionsAreEmpty && (
            <>Error retrieving production details.</>
          )}
        </Box>
      )}

      {productionsStatus !== "loading" && productions && productions.length > 0 && (
      <DateProvider>
        <CategoryProvider>
          <SearchProvider>
            <DashboardSection
              bgColor="default"
              size="medium"
              bgImage=""
              bgImageOpacity={1}
              title={productions[0]?.productionName}
              subtitle=""
              company={productions[0]?.businessName}
              productionId={productions[0]?.id}
            />
          </SearchProvider>
        </CategoryProvider>
      </DateProvider>
      )}
    </>
  ) : <></>;
}

export default requireAuth(DashboardPage);
