import React, { useState, useEffect, useRef, useContext } from "react";
import Box from "@material-ui/core/Box";
import { getGroupsByProductionIdOnce, getLocationsByProductionIdOnce } from "../util/db";
import Paper from "@material-ui/core/Paper";
import { useTheme } from '@material-ui/core/styles';
import SearchedLocationListItem from "./SearchedLocationListItem";
import { SearchContext } from "../providers/SearchProvider";
import useMediaQuery from '@material-ui/core/useMediaQuery';

function SearchedLocationList(props) {
            
    const theme = useTheme();
    const locationsRef = useRef([]);
    const groupsRef = useRef([]);
    const [combinedLocsGroups, setCombinedLocsGrups] = useState([])
    const [filteredLocations, setFilteredLocations] = useState([]);
    const [loading, setLoading] = useState(true); // Track loading state
    const { setSearchFocused, setPreventBlur} = useContext(SearchContext);
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));   
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));   
    
    const flexRow = {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px solid gray',
      padding: 8,
      fontWeight: '900',
    }
const flexCol = {
  marginRight: 10,
}
  // fetch locations, groups, combine
  useEffect(() => {

    // get locations
    const fetchAllLocations = async () => {
      try {
        const fetchedLocations = await getLocationsByProductionIdOnce(props.productionId);
        locationsRef.current = fetchedLocations;
      } catch (error) {
        console.error('Error fetching locations:', error);
      }
    };

    // get groups
    const fetchAllGroups = async () => {
      try {
        const fetchedGroups = await getGroupsByProductionIdOnce(props.productionId);
        groupsRef.current = fetchedGroups;
      } catch (error) {
        console.error('Error fetching groups:', error);
      }
    };

    // combine location and group data
    const combineLocationAndGroupData = async () => {
     await fetchAllLocations();
      await fetchAllGroups();

      const updateLocations = locationsRef.current.map(location => {
      // Find the corresponding object from group array by id
      const matchedGroup = groupsRef.current.find(group => group.id === location.groupId);

      // If a match is found, spread both objects together to update the location
      return matchedGroup
        ? { ...location, groupName: matchedGroup.name, groupColor: matchedGroup.groupColor, startDate: matchedGroup.startDate, endDate: matchedGroup.endDate } // Combine location and group info
        : location; // Return the original location if no match is found
    });
    let sortedCombined = updateLocations.sort((a, b) => Date.parse(new Date(b.day)) - Date.parse(new Date(a.day)));
    setCombinedLocsGrups(sortedCombined);
    setLoading(false);
  }

  combineLocationAndGroupData();
  
}, [props.productionId]); 

  // react to search text canging
  useEffect(() => {
    const matchesSearch = (obj, searchStr) => {
      return Object.values(obj).some(value => {
        // If value is an object, recursively check its properties
        if (typeof value === 'object' && value !== null) {
          return matchesSearch(value, searchStr);
        }
        // If value is a string, check if it includes the search string
        return typeof value === 'string' && value.toLowerCase().includes(searchStr.toLowerCase());
      });
    };
  
    if (props.searchText) {
      const filtLoc = combinedLocsGroups?.filter(loc =>
        matchesSearch(loc, props.searchText)
      );
      setFilteredLocations(filtLoc);
    } else {
      setFilteredLocations(combinedLocsGroups);
    }
  }, [combinedLocsGroups, props.searchText]);

  const handleMouseEnter = (e) => {
    setPreventBlur(true);
  };

  const handleMouseLeave = (e) => {
    setPreventBlur(false);
  };

  return (
    <>       
    <Paper style={{backgroundColor: theme.backgroundColor}}>

        {(loading) && (
          <Box py={2} px={3} align="center">
            {!combinedLocsGroups.length && <span>FETCHING LOCATIONS...</span>}
          </Box>
        )}

        {(!loading && !filteredLocations.length && props.searchText) && (
          <Box py={2} px={3} align="center">
            <span>Your search criteria did not match any locations.</span>
          </Box>
        )}

        {filteredLocations && (filteredLocations?.length > 0) && 
            // <List disablePadding={true}>
            <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              {!isXs && <li style={flexRow}>
                <div style={{...flexCol, width: '200px'}}>TYPE</div>
                <div style={{...flexCol, width: '200px'}}>NAME</div>
                <div style={{...flexCol, width: '200px'}}>GROUP</div>
                <div style={{...flexCol, width: '400px'}}>ADDRESS</div>
                <div style={{...flexCol, width: '100px'}}>CATEGORY</div>
                <div style={{...flexCol, width: '150px'}}>DAY</div>
              </li>}
              {filteredLocations?.map((location, index) => (
                <SearchedLocationListItem key={index} location={location} isXs={isXs} locationLength={combinedLocsGroups.length} index={index} groupColor={props.groupColor} isSmall={isSmall} groupId={props.groupId} groupName={combinedLocsGroups.groupName} groupCustomTypes={props.groupCustomTypes} />
              ))}
              </div>
          }
        </Paper>
    </>
  );
}

export default SearchedLocationList;
