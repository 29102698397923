import React, { useContext } from "react";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { CategoryContext } from "../providers/CategoryProvider";


const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: '#22273eff',
    marginBottom: '10px',
    alignItems: 'center'
  }
}));

function LocationFilterBar() {
    const {category, setCategory} = useContext(CategoryContext);
    const classes = useStyles();
    const theme = useTheme();
    const ifSmall = useMediaQuery(theme.breakpoints.down('xs'));
  
    const handleChange = (event, cat) => {
        setCategory(cat)
    };

  return (
    <>              
    <AppBar position="static" className={classes.appBar} >
      <Tabs value={category} onChange={handleChange} aria-label="simple tabs example">
        <Tab label={ ifSmall ? 'Prep/Wrap' : 'Prep / Wrap Locations'}  value={'prepwrap'}/>
        <Tab label={ ifSmall ? 'Shooting' : 'Shooting Locations'} value={'shooting'}/>
        <Tab label={ ifSmall ? 'Production' : 'Production Locations'} value={'production'}/>
      </Tabs>
    </AppBar>
    </>
  );
}

export default LocationFilterBar;
