import React, { createContext, useState } from 'react';

// Create the context
export const SearchContext = createContext();

// Create the provider component
export const SearchProvider = ({ children }) => {
  // Create state inside the provider
  const [searchFocused, setSearchFocused] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [preventBlur, setPreventBlur] = useState(false);

  return (
    <SearchContext.Provider value={{ searchFocused, setSearchFocused, searchText, setSearchText, preventBlur, setPreventBlur }}>
      {children}
    </SearchContext.Provider>
  );
};
