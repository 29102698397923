
import React, { useState, useEffect, useCallback, useRef, createRef, useMemo} from "react";
import { GoogleMap, DirectionsRenderer, DirectionsService, Marker } from "@react-google-maps/api";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../util/auth";

const useStyles = makeStyles((theme) => ({
    modalContainer: {
        padding: 20,
        background: 'white'
    },
    pdfContainer: {
        backgroundColor: 'white',
        color: 'black',
        padding: 10,
        border: '4px solid black',
    },
    flexContainerMaps: {
        display: 'flex',
        flexDirection: 'row-reverse',
        minWidth: '100%',
    },
    flexContainerTitles: {
        display: 'flex',
        flexDirection: 'row',
        minWidth: '100%',
        paddingLeft: 10,
        paddingRight: 10,
        borderBottom: '2px solid black'
    },
    directionsStep: {
      backgroundColor: 'white',
      color: 'black',
      flex: 1
    },
    mapSection: {
        minWidth: '50%',
        flex: 1
    },
    toFrom: {
      width: '100%',
    },
    showName: {
        marginLeft: 'auto',
        width: '40%',
        textAlign: 'right'
    },
    startLocation: {
      marginBottom: -20
    },
    cLabel: {
      display: 'none !inmportant'
    }
  }));

function MapDirections({to, from, disableDefaultUI}) {

    const auth = useAuth();
    const [pending, setPending] = useState(false);
    const [directionsResponse, setDirectionsResponse] = useState(null);
    const directionsPanelRef = useRef(null);   // { current: null }
    const [newDestEqualsOld, setNewDestEqualsOld] = useState(true);
    const [directionsServiceOption, setDirectionsServiceOption] = useState({
    origin: { lat: from?.mapData.coords.lat, lng: from?.mapData.coords.lng },
    destination: { lat: to?.mapData.coords.lat, lng: to?.mapData.coords.lng },
    travelMode: "DRIVING"
})

const classes = useStyles();

    const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
      var R = 6371; // Radius of the earth in kilometers
      var dLat = deg2rad(lat2 - lat1); // deg2rad below
      var dLon = deg2rad(lon2 - lon1);
      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c; // Distance in KM
      return d;
    }
    
    const deg2rad = (deg) => {
      return deg * (Math.PI / 180)
    }

    const checkIfNewDestEqualsOld = (data) => {
      var lastLeg = data?.routes?.[0].legs?.slice(-1);
      var newDest = {lat: lastLeg?.[0].end_location?.lat(), lng: lastLeg?.[0].end_location?.lng()};
      if (newDest !== directionsServiceOption.destination) {
        // Convert distance from km to feet.. if we are within 25 ft, dont bother showing hte 3rd marker aka real dest. if it is greater, show it so people can see where theyre really supposed to go.
        var distanceFromNewToOldInFeet = getDistanceFromLatLonInKm(newDest.lat, newDest.lng, directionsServiceOption.destination.lat, directionsServiceOption.destination.lng) * 3280.84;
        if (distanceFromNewToOldInFeet < 25) {
          setNewDestEqualsOld(true);
        } else {
          setNewDestEqualsOld(false);
        }
      } else {
        setNewDestEqualsOld(true);
      }
    }

      const directionsCallback = useCallback(res => {
          if (res) {
            checkIfNewDestEqualsOld(res);
          // if (res.status === 'OK') {
          //     setDirectionsResponse(res);
          // }
          setDirectionsResponse(res);
        }
        }, [to, from]);

    useEffect(() => {
        setDirectionsServiceOption({
          origin: { lat: from?.mapData.coords.lat, lng: from?.mapData.coords.lng },
          destination: { lat: to?.mapData.coords.lat, lng: to?.mapData.coords.lng },
          travelMode: "DRIVING"
        })
      }, [to, from]);

      const renderGoogleMap = useMemo(() => {
        return <><div className={classes.mapSection} >
        <GoogleMap
        options={{gestureHandling: 'greedy', disableDefaultUI: disableDefaultUI,
      }}
           mapContainerClassName="map-container"
         >
      {directionsResponse !== null && (
         <DirectionsRenderer
           options={{
             draggable: true,
             markerOptions: {
              zIndex: 999,
              optimized: true,
              visible: true,
            }
           }}
           directions={directionsResponse}
           panel={directionsPanelRef.current}
         />
         
       )}
       <DirectionsService
         options={directionsServiceOption}
         callback={directionsCallback}
       />
         {!newDestEqualsOld && <Marker 
           position={{ lat: to?.mapData.coords.lat, lng: to?.mapData.coords.lng }}
           label={{text:`C`,color:'#fff', fontWeight: 'bold', top: 20}}
           title="ACTUAL DESTINATION"
           zIndex={998}
           />}
         </GoogleMap>
         </div></>
      }, [to, from, directionsResponse, disableDefaultUI]);

      return (
        
        <div className={classes.modalContainer}>
  
        <div className={classes.pdfContainer}>
        <div className={classes.flexContainerTitles}>
          <div className={classes.toFrom}>
          <h4 className={classes.startLocation}>FROM: {from?.type} {from?.name && <span>-</span>} {from?.name} {from?.mapData.address}</h4>
        <h4 className={classes.toLocation}>TO: {to?.type} {to?.name && <span>-</span>} {to?.name} {to?.mapData.address}</h4>
          </div>
        <h1 className={classes.showName}>{auth.user.productionName}</h1>
        </div> 
        <div className={classes.flexContainerMaps}> 
         {renderGoogleMap}
         <div ref={directionsPanelRef} className={classes.directionsStep}><p></p>
         {directionsResponse?.status == 'ZERO_RESULTS' && <h4>Sorry, no direction results for these locations.</h4>}
         </div>
        </div>
        </div>
        </div>
      );
    }

    export default MapDirections;
