import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useForm } from "react-hook-form";
import { useAuth } from "./../util/auth";
import { updateProduction, useProductionsByOwner } from "../util/db";
import dayjs from 'dayjs';


function SettingsGeneral(props) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const {
    data: productions,
    status: productionsStatus,
    error: productionsError,
  } = useProductionsByOwner(auth.user.uid);

  const today = dayjs(new Date()).format("YYYY-MM-DDTHH:mm");

  const productionsAreEmpty = !productions || productions.length === 0;

  const onSubmit = (data) => {
    // Show pending indicator
    setPending(true);
    console.log(productions[0].id)
    var modifiedData = {
      "businessName": data.businessName,
      "businessAddress": data.businessAddress,
      "productionName": data.productionName,
      "productionNameAsShown": data.productionNameAsShown,
      "email": data.email,
      "accessKey": {"type":"crew", "key": data.accessKey, "expiry":false},
      "accessKeyForExtras": {"type":"extra", "key": data.accessKeyForExtras, "expiry":data.accessKeyForExtrasExpiry}
  }
    updateProduction(productions[0].id, modifiedData)
    return auth
      .updateProfile(modifiedData)
      .then(() => {
        // Set success status
        props.onStatus({
          type: "success",
          message: "Your production settings have been updated",
        });
      })
      .catch((error) => {
        if (error.code === "auth/requires-recent-login") {
          props.onStatus({
            type: "requires-recent-login",
            // Resubmit after reauth flow
            callback: () => onSubmit(modifiedData),
          });
        } else {
          // Set error status
          props.onStatus({
            type: "error",
            message: error.message,
          });
        }
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });
  };

  return (
    <>
    {productionsError && (
      <Box mb={3}>
        <Alert severity="error">{productionsError.message}</Alert>
      </Box>
    )}

  {(productionsStatus === "loading" || productionsAreEmpty) && (
      <Box py={5} px={3} align="center">
        {productionsStatus === "loading" && <CircularProgress size={32} />}

        {productionsStatus !== "loading" && productionsAreEmpty && (
          <>An error occurred retrieving production data.</>
        )}
      </Box>
    )}
    
    {productionsStatus !== "loading" && productions && productions.length > 0 && (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <TextField
            variant="outlined"
            type="text"
            label="Business Name" 
            name="businessName"
            placeholder="Business Name"
            defaultValue={productions[0]?.businessName}
            error={errors.businessName ? true : false}
            helperText={errors.businessName && errors.businessName.message}
            fullWidth={true}
            inputRef={register({
              required: "Please enter the business name",
            })}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            variant="outlined"
            type="text"
            label="Business Address"
            name="businessAddress"
            placeholder="Business Address"
            defaultValue={productions[0]?.businessAddress}
            error={errors.businessAddress ? true : false}
            helperText={errors.businessAddress && errors.businessAddress.message}
            fullWidth={true}
            inputRef={register({
              required: "Please enter the business Address",
            })}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            variant="outlined"
            type="text"
            label="Production Name"
            name="productionName"
            placeholder="Production Name"
            defaultValue={productions[0]?.productionName}
            error={errors.productionName ? true : false}
            helperText={errors.productionName && errors.productionName.message}
            fullWidth={true}
            inputRef={register({
              required: "Please enter the production name",
            })}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            variant="outlined"
            type="text"
            label="Production Name as Shown"
            name="productionNameAsShown"
            placeholder="Production Name as Shown"
            defaultValue={productions[0]?.productionNameAsShown}
            error={errors.productionNameAsShown ? true : false}
            helperText={errors.productionNameAsShown && errors.productionNameAsShown.message}
            fullWidth={true}
            inputRef={register({
              required: "Please enter the production name as shown",
            })}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            variant="outlined"
            type="email"
            label="Email"
            name="email"
            placeholder="user@example.com"
            defaultValue={auth.user.email}
            error={errors.email ? true : false}
            helperText={errors.email && errors.email.message}
            fullWidth={true}
            inputRef={register({
              required: "Please enter your email",
            })}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            variant="outlined"
            type="text"
            label="Access Key for Crew"
            name="accessKey"
            placeholder="MyShow123"
            defaultValue={productions[0]?.accessKey?.key}
            error={errors.accessKey ? true : false}
            helperText={errors.accessKey && errors.accessKey.message}
            fullWidth={true}
            inputRef={register({
              required: "Please enter an access key",
            })}
          />
        </Grid>
        <Grid item={true} xs={12} sm={6}>
          <TextField
            variant="outlined"
            type="text"
            label="Access Key for Extras"
            name="accessKeyForExtras"
            placeholder="MyShow123EXTRA"
            defaultValue={productions[0]?.accessKeyForExtras?.key}
            error={errors.accessKeyForExtras ? true : false}
            helperText={errors.accessKeyForExtras && errors.accessKeyForExtras.message}
            fullWidth={true}
            inputRef={register({
              required: "Please enter an access key for Extras",
            })}
          />
        </Grid>
        <Grid item={true} xs={12} sm={6}>
          <TextField
            variant="outlined"
            type="datetime-local"
            name="accessKeyForExtrasExpiry"
            label="Extras Key Expiration"
            defaultValue={productions[0]?.accessKeyForExtras?.expiry}
            error={errors.accessKeyForExtrasExpiry ? true : false}
            helperText={errors.accessKeyForExtrasExpiry && errors.accessKeyForExtrasExpiry.message}
            fullWidth={true}
            inputRef={register({
              required: "Please enter an expiration for the extra's key",
            })}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled={pending}
            fullWidth={true}
          >
            {!pending && <span>Save</span>}

            {pending && <CircularProgress size={28} />}
          </Button>
        </Grid>
      </Grid>
    </form>
    )}
    </>
  );
}

export default SettingsGeneral;
