import React, { useState, useEffect, useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { useAuth } from "../util/auth";
import MapDirections from "./MapDirections";
import Grid from "@material-ui/core/Grid";
// import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

const useStyles = makeStyles((theme) => ({
  content: {
    paddingBottom: 24
  },
  modalWindow: {
    minBlockSize: '90%'
  }
}));

function MapDirectionsPDFModal({onDone, destination, setName}) {


  const classes = useStyles();
  const auth = useAuth();
  const [generatingPDF, setGeneratingPDF] = useState(false);

  const printDocument = (domElement) => {
    // setGeneratingPDF(true);
    // html2canvas(domElement, {scrollY: -window.scrollY, useCORS: true}).then(canvas => {
    //   // document.body.appendChild(canvas);
    //   // console.log(canvas)
    //   // const dataUrl = canvas.toDataURL("png"); 
    //   var imgdata = canvas.toDataURL("image/jpg");
    //   var pdf = new jsPDF();
    //   const imgProps= pdf.getImageProperties(imgdata);
    //   const pdfWidth = pdf.internal.pageSize.getWidth();
    //   const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

    //   pdf.addImage(imgdata, 'PNG', 0, 0, pdfWidth, pdfHeight);
    //   pdf.save("directions.pdf");
    //   setGeneratingPDF(false);
    //   onDone();
    // });
  };
  
  const canvasRef = useRef()


  // useEffect(() => {

  // }, []);

  return (
   <Dialog
   fullScreen={true}
    className={classes.modelWindow} 
    disableEnforceFocus
    style={{ pointerEvents: 'none' }}
    PaperProps={{ style: { pointerEvents: 'auto' } }}
    open={true} onClose={onDone} fullWidth={true} maxWidth='lg'>
      <DialogTitle className="editHeader">Turn by Turn Directions</DialogTitle>
      <DialogContent 
      className={classes.content}>
            <div
            ref={canvasRef}
            >
            <MapDirections destination={destination} setName={setName} />
            </div>
        <Grid item={true} xs={12} className="editFooter">
            <Button
                variant="contained"
                color="secondary"
                size="large"
                disabled={generatingPDF}
                onClick={onDone}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={generatingPDF}
                onClick={()=>printDocument(canvasRef.current)}
              >
                <span>{generatingPDF ? "Loading..." : 'Generate PDF'}</span>
              </Button>
            </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default MapDirectionsPDFModal;
