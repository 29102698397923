import { GoogleMap, Marker } from "@react-google-maps/api";
import Geocode from "react-geocode";
import "@reach/combobox/styles.css";

function Map({ setMapData, mapData, callSearchBoxSelect }) {
    
      const onMarkerDragEnd = (event) => {

        let newLat = event.latLng.lat();
        let newLng = event.latLng.lng();

        Geocode.fromLatLng(newLat, newLng).then(
            response => {
              console.log('testing map data = ' + JSON.stringify(response.results))
                var newAddress = (!response.results[0].address_components[0].types.includes("plus_code") ? response.results[0].formatted_address 
                : !response.results[1].address_components[1].types.includes("plus_code") ? response.results[1].formatted_address
                : !response.results[2].address_components[2].types.includes("plus_code") ? response.results[2].formatted_address
                : !response.results[3].formatted_address);
                setMapData({address: newAddress, coords: {lat: newLat, lng:newLng}});
                console.log('setting map data form marker drag to new object: ' + JSON.stringify(mapData))
                callSearchBoxSelect(newAddress, true);
            },
            error => {
              console.error(error);
            }
          );
    
    };
    
      return (
        <>    
          <GoogleMap
            options={{gestureHandling: 'greedy'}}
            zoom={16.5}
            center={mapData?.coords}
            mapContainerClassName="map-container"
          >
            <Marker 
            draggable={true} 
            position={mapData?.coords}
            onDragEnd={onMarkerDragEnd}/>
          </GoogleMap>
        </>
      );
    }
    export default Map;