import React, { createContext, useState } from 'react';

const todaysDate = new Date().toDateString();
const todaysDateStamp = new Date();
todaysDateStamp.setHours(0,0,0,0);

// Create the context
export const DateContext = createContext();

// Create the provider component
export const DateProvider = ({ children }) => {

  // Create state inside the provider
  const [day, setDay] = useState(todaysDate);
  const [dayStamp, setDayStamp] = useState(todaysDateStamp.getTime());

  return (
    <DateContext.Provider value={{ day, setDay, dayStamp, setDayStamp }}>
      {children}
    </DateContext.Provider>
  );
};
