import React, { useRef, useContext, useEffect } from "react";
import { SearchContext } from '../providers/SearchProvider';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const ExpandingSearchField = () => {
    const { searchFocused, setSearchFocused, searchText, setSearchText, preventBlur, setPreventBlur } = useContext(SearchContext);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));   

    const spanStyle = {
        cursor: 'pointer',
        position: 'absolute',
        zIndex: 200,
        right: '20px',
        paddingLeft: 40,
        width: '100%'
    }

    const inputStyle = {
        backgroundColor: 'transparent',
        border: '3px solid #fff',
        borderWidth: searchFocused ? '2px' : '',
        borderRadius: searchFocused ? '20px' : '15px',
        color: 'white',
        cursor: searchFocused ? 'text' : 'pointer',
        fontSize: '15px',
        height: searchFocused ? '40px' : '30px',
        cssFloat: 'right',
        outline: 'none',
        transition: 'all 0.3s ease',
        width: searchFocused ? isSmall ? '100%' : '300px' : '30px',
        lineHeight: searchFocused ? '1.6' : '20',
        display: 'inline',
        padding: searchFocused ? '0 20px' : '',
    }

    const labelStyle = {
        backgroundColor: 'white',
        bottom: searchFocused ? '6px' : '-6px',
        display: 'block',
        right: searchFocused ? '6px' : '0',
        position: 'absolute',
        height: searchFocused ? '1px' : '12px',
        transform: 'rotate(-45deg)',    
        transition: 'all 0.3s ease',
        width: '3px'
    }

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
    if (event.target.value === '') {
        setSearchText(null);
    }
  };

  const handleBlur = (event) => {
    if (!preventBlur) {
        setSearchText('');
        setSearchFocused(false);    
    } 
  };       

  return (
        <span style={spanStyle}>
            <input
                autocomplete="off"
                placeholder={searchFocused ? 'Search All Locations' : ''}
                style={inputStyle}
                type="search" id="search" 
                value={searchText}
                onFocus={() => [setSearchFocused(true), setPreventBlur(false)]}
                onBlur={handleBlur}
                onChange={handleSearchTextChange} // Call the function on every value change
            />
            <label style={labelStyle} for="search"></label>
        </span>
  );
};

export default ExpandingSearchField;

