import React, { useState, useEffect, useCallback } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useForm } from "react-hook-form";
import { deleteLocationGroupCustomType } from "../util/db";
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import CheckboxField from '@material-ui/core/Checkbox';

function CustomTypeDialogDelete(props) {
  const [pending, setPending] = useState(false);
  const [typesToDelete, setTypesToDelete] = useState([]);
  const { register, handleSubmit, errors } = useForm();

  const handleChange = (event) => {
    var theType = {orderNumber: parseInt(event.target.value), type: event.target.name};
    if (event.target.checked === true) {
      setTypesToDelete([
        ...typesToDelete,
        theType
      ])
    } else if (event.target.checked === false) {
      setTypesToDelete((types) => types.filter((type) => type.type !== theType.type))
    }
    console.log(JSON.stringify(typesToDelete))
  };

  const onSubmit = () => {
    setPending(true);
    typesToDelete.forEach(type => {
      deleteLocationGroupCustomType(props.groupId, type)
    });
    console.log(JSON.stringify(typesToDelete))
    handleCloseDeleteCustomType();
  }

    const handleCloseDeleteCustomType = () => {
    setTypesToDelete([]);
    setPending(false);
    props.setOpen(false);
    };

  return (
    <>
      {/* DELETE CUSTOM TYPE */}
      <Dialog
            open={props.open}
            onClose={handleCloseDeleteCustomType}
        >
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle style={{paddingBottom: 0}}>Delete Custom Types</DialogTitle>
      <DialogContent>
      <Grid item={true}>
        <FormGroup>
        {props.customTypes?.sort((a,b) => a.orderNumber - b.orderNumber)
            .map(theType => (
              <FormControlLabel
              control={<CheckboxField onChange={handleChange} name={theType.type} value={theType.orderNumber} />}
              label={theType.type}
            />
             ))}
        </FormGroup>
        </Grid>
        <DialogActions>
          <Button variant="contained" autoFocus onClick={handleCloseDeleteCustomType} color="secondary">
            Cancel
          </Button>
          <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={pending || typesToDelete?.length === 0}
              >
                {!pending && <span>DELETE</span>}
                {pending && <CircularProgress size={28} />}
              </Button>
        </DialogActions>
        </DialogContent>
      </form>
      </Dialog>
      </>
  );
}

export default CustomTypeDialogDelete;
