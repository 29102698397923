import React, {useState, useEffect, forwardRef, useContext} from "react";
import {Route, Link, Routes, useLocation} from 'react-router-dom';
import Box from "@material-ui/core/Box";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import DatePicker from "react-datepicker";
import { useAuth } from "./../util/auth";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { DateContext } from "../providers/DateProvider";
import "react-datepicker/dist/react-datepicker.css";
import "../../src/styleOverrides.css";

const useStyles = makeStyles((theme) => ({
  root: {
    // Add bottom margin if element below
    "&:not(:last-child)": {
      marginBottom: "2rem",
    },
  },
  date: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  theDay: {
    width:'400px',
    cursor:'pointer',
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  theDayMobile: {
    cursor:'pointer',
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  theDayDisabled: {
    // width:'400px',
    cursor: "default",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: 'gray'
  }
}));
const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];

// const todaysDate = new Date().toDateString();
  
function DateCarousel(props) {
  const {day, setDay, dayStamp, setDayStamp} = useContext(DateContext)
  const location = useLocation();
  const classes = useStyles();
  // const [day, setDay] = useState(todaysDate);
  const { subtitle, title, size, className, ...otherProps } = props;
  const [startDate, setStartDate] = useState(new Date());
  const theme = useTheme();
  const ifSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const auth = useAuth();

  useEffect(() => {
    if (setDay) {
      setDay(day);
      setDayStamp(dayStamp);
    }
  }, [day, dayStamp, setDay, setDayStamp]);

  const prevDay = () => {
    var dateObj = new Date(day);
    const prevDay = new Date(new Date(dateObj).setDate(dateObj.getDate() - 1))
    setDay(prevDay.toDateString());
    setDayStamp(prevDay.getTime());
  };

  const nextDay = () => {
    var dateObj = new Date(day);
    const nextDay = new Date(new Date(dateObj).setDate(dateObj.getDate() + 1))
    setDay(nextDay.toDateString());
    setDayStamp(nextDay.getTime());
  };

  function readableDay(separator=', ') {
    let newDate = new Date(day)
    let date = newDate.getDate();
    let theDay = weekday[newDate.getDay()];
    let month = monthNames[newDate.getMonth()]
    let year = newDate.getFullYear();
    return `${theDay}, ${month<10?`0${month}`:`${month}`} ${date}${separator}${year}`
  }

  function mobileDay(separator='/') {
    let newDate = new Date(day)
    const yyyy = newDate.getFullYear();
    let mm = newDate.getMonth() + 1; // month is zero-based
    let dd = newDate.getDate();
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    const formatted = mm + '/' + dd + '/' + yyyy;
    console.log(formatted); // 24/04/2023
    return formatted;
  }

  const DateTitlePickerInput = forwardRef(({ value, onClick }, ref) => (
      <div className={!props.disabled ? ifSmall ? classes.theDayMobile : classes.theDay : classes.theDayDisabled} onClick={onClick} ref={ref}>
         {ifSmall ? mobileDay() : readableDay() } {"    "}
         <DateRangeIcon style={{marginLeft: 5}} color={props.disabled ? "gray" : "secondary"} />
      </div>
  ));

  return (
    <Box className={classes.root + (props.className ? ` ${props.className}` : "")}>
      <Typography variant="h5">
        {auth.user.planIsActive && <div className={classes.date}>
          <Button disabled={props.disabled} onClick={prevDay}><ChevronLeftIcon fontSize="large" color={props.disabled ? "gray" : "secondary"}/></Button>
           <DatePicker
           disabled={props.disabled}
            selected={new Date(day)}
            onChange={(date) => setDay(date.toDateString(), setDayStamp(date.getTime()))}
            customInput={<DateTitlePickerInput />}
            todayButton="Today"
            withPortal
            />
          <Button disabled={props.disabled} onClick={nextDay}><ChevronRightIcon fontSize="large" color={props.disabled ? "gray" : "secondary"}/></Button>
          </div>}
        </Typography>
    </Box>
  );
}

export default DateCarousel;
